import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useDeviceType, { useIsTouchDevice } from "helpers";
import { IHomeProject } from "types/home";
import { SubTitle } from "components/styled/SubTitle";
import HomeSingleOffer, { SinleOffer } from "./homeOffer";
import DiscoverMore from "components/styled/DiscoverMore";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Slider } from "components/styled/Slider";

interface IProps {
  offers: IHomeProject[];
}

export const deviceBreakpoints = {
  mobile: 480,
  tablet: 744,
  md: 1280,
  lg: 1680,
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);

const Offers: FC<IProps> = ({ offers }) => {
  const { t } = useTranslation();
  const touchDevice = useIsTouchDevice();
  const [screenSizes, setScreenSizes] = useState<number>(1);
  const deviceType = useDeviceType();
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (imgRef.current) {
        setImgWidth(imgRef?.current?.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [imgRef?.current?.offsetWidth]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (offers.length < 4) {
        setScreenSizes(offers.length);
      } else {
        if (screenWidth > 480 && screenWidth <= 743) {
          setScreenSizes(1.5);
        } else if (screenWidth >= 744 && screenWidth < 1024) {
          setScreenSizes(2.5);
        } else if (screenWidth >= 1024 && screenWidth < 1248) {
          setScreenSizes(3.5);
        } else if (screenWidth >= 1248) {
          setScreenSizes(4);
        } else if (screenWidth <= 480 && screenWidth > 320) {
          setScreenSizes(1.5);
        } else {
          setScreenSizes(1.2);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [offers.length]);

  return (
    <>
      <div className="container">
        <SubTitle className="mb-300">{t("offers")}</SubTitle>
      </div>

      {deviceType === "mobile" ? (
        <div
          className="display-flex flex-column pl-250 pr-250 similar-mobile"
          style={{ gap: "24px" }}
        >
          {offers.slice(0, 5).map((item, index) => (
            <SinleOffer key={index}>
              <img
                ref={imgRef}
                src={item.img}
                alt={item.title}
                style={{ height: imgWidth / 1.33 }}
              />

              <h3>{item.title}</h3>
              <p>{item.short_description}</p>
            </SinleOffer>
          ))}
        </div>
      ) : (
        <Slider>
          <div className="slider-services">
            <div
              className={`container arrows ${touchDevice ? "" : "with-hover"}`}
            >
              <button className="arrow-left arrow">
                <ArrowLeft />
              </button>
              <button className="arrow-right arrow">
                <ArrowLeft />
              </button>
            </div>

            <Swiper
              navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
              slidesPerView={screenSizes}
              freeMode={true}
              initialSlide={0}
              className="offer-swiper"
            >
              {offers.map((item, index) => (
                <SwiperSlide className="slider-item" key={`room-${index}`}>
                  <HomeSingleOffer {...item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Slider>
      )}

      <div className="display-flex justify-center mt-600">
        <DiscoverMore url="/offers" />
      </div>
    </>
  );
};

export default Offers;
