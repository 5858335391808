import ListItem, { IListItem } from "../news";
import DiscoverMore from "components/styled/DiscoverMore";
import { FC, useEffect, useState } from "react";
import { SimilarProjects } from "pages/Projects/styled";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { useIsTouchDevice } from "helpers";
import { Slider } from "components/styled/Slider";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";

interface IProps {
  projects: IListItem[];
  title?: string;
  discoverMore?: boolean;
}

export const deviceBreakpoints = {
  mobile: 480,
  tablet: 744,
  md: 1280,
  lg: 1680,
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);

const HomeProjects: FC<IProps> = ({ projects, title, discoverMore }) => {
  const { t } = useTranslation();
  const [screenSizes, setScreenSizes] = useState<number>(1);
  const touchDevice = useIsTouchDevice();

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (
        screenWidth >= deviceBreakpoints.tablet &&
        screenWidth < deviceBreakpoints.md
      ) {
        setScreenSizes(2.5);
      } else if (
        screenWidth >= deviceBreakpoints.md &&
        screenWidth < deviceBreakpoints.lg
      ) {
        setScreenSizes(3.5);
      } else if (screenWidth >= deviceBreakpoints.lg) {
        setScreenSizes(4);
      } else {
        setScreenSizes(1);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [projects.length]);

  return (
    <>
      <SimilarProjects className="mt-1000">
        {!!projects?.length && (
          <div className="container">
            <h2 className="mt-0 mb-300">
              {title ? title : t("findDesiredModel")}
            </h2>
          </div>
        )}

        <Slider>
          <div className="slider-services">
            <div
              className={`container arrows ${touchDevice ? "" : "with-hover"}`}
            >
              <button className="projects-arrow-left arrow">
                <ArrowLeft />
              </button>
              <button className="projects-arrow-right arrow">
                <ArrowLeft />
              </button>
            </div>

            <Swiper
              navigation={{
                nextEl: ".projects-arrow-right",
                prevEl: ".projects-arrow-left",
              }}
              slidesPerView={screenSizes}
              freeMode={true}
              initialSlide={0}
              className="project-swiper"
            >
              {projects.map((item, index) => (
                <SwiperSlide className="slider-item" key={`room-${index}`}>
                  <ListItem
                    showDesc
                    superSmallImg
                    projectsList
                    {...item}
                    url={`/product/${item.url}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Slider>
      </SimilarProjects>

      {discoverMore && (
        <div className="display-flex justify-center mt-600">
          <DiscoverMore url="/products" />
        </div>
      )}
    </>
  );
};

export default HomeProjects;
